<template>
  <v-container class="pa-4">
    <div class="font-weight-bold text-h4">
      {{ $t('openTimeSetting') }} {{ trueDefaultTime?.weekDay?.name }}
    </div>
    <div class="mb-2 mt-8">
      {{ $t('setDefaultTime') }}
    </div>
    <v-form
      ref="startTime"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="reserveTime"
        :placeholder="$t('estimatedMealTime')"
        dense
        hide-details
        outlined
        step="0.1"
        suffix="小时"
        type="number"
      />
    </v-form>
    <div class="mt-6">
      {{ $t('openTimeSetting') }}
    </div>
    <div
      v-for="(a,index) in trueList"
      :key="a.key"
      class="d-flex align-center mb-1 mt-2"
    >
      <v-select
        v-model="a.startTime"
        :items="selectTimeList"
        :label="$t('startOpenTime')"
        hide-details
        outlined
      />
      <v-divider
        class="ml-2 mr-2"
        vertical
      />
      <v-select
        v-model="a.endTime"
        :items="selectTimeList"
        :label="$t('endOpenTime')"
        hide-details
        outlined
      />
      <div style="width: 60px">
        <v-btn
          :disabled="trueList.length===0||(!a.startTime&&!a.endTime)"
          class="ml-1"
          icon
          @click="trueList.splice(index, 1)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="d-flex">
      <v-spacer/>
      <v-btn
        class="primary mt-4 mr-4"
        @click="save"
      >
        {{ $t('save') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import IKUtils from 'innerken-js-utils'
  import OpeningTime from '@/model/takeaway/OpeningTime'
  import { timeArrays } from '@/model/common/OpenTimeList'
  import { editReserveDefaultUsefulTime } from '@/common/Utlis/api'

  let counter = 0
  const defaultDate = {
    startTime: '',
    endTime: '',
  }

  export default {
    name: 'NewAddForm',
    components: { timeArrays },
    props: {
      searchOpenTime: {},
      showList: {},
      dayOfWeek: {
        type: Number,
      },
      defaultReserveTime: {},
    },
    data: function () {
      return {
        oldShowList: null,
        selectTimeList: null,
        timeArrays,
        isLoading: false,
        trueDefaultTime: null,
        reserveTime: null,
        valid: true,
        trueList: false,
      }
    },
    watch: {
      dayOfWeek (val, old) {
        if (old) {
          this.reload()
        }
      },
      trueList: {
        deep: true,
        handler (val) {
          const emptyNode = val.filter(it => !it.startTime && !it.endTime).length
          if (emptyNode === 0) {
            this.trueList.push(this.createDefaultAttribute())
          }
        },
      },
    },
    mounted () {
      this.reload()
    },
    methods: {
      reload () {
        this.trueList = this.showList.filter(it => it.weekday[0] === this.dayOfWeek)
        this.oldShowList = this.showList.filter(it => it.weekday[0] === this.dayOfWeek)
        this.trueDefaultTime = this.defaultReserveTime.find(it => it.dayOfWeek === this.dayOfWeek)
        this.reserveTime = this.trueDefaultTime?.useTime
        this.selectTimeList = timeArrays.map(it => it.start)
      },
      async save () {
        await editReserveDefaultUsefulTime({
          dayOfWeek: this.dayOfWeek,
          gapCount: this.reserveTime * 4,
        })
        for (const items of this.oldShowList) {
          await OpeningTime.remove(items.id)
        }
        for (const item of this.trueList) {
          if (item.startTime !== '' && item.endTime !== '') {
            await OpeningTime.add({
              ...item,
              weekday: this.dayOfWeek,
            })
          }
        }
        this.$emit('reload')
      },
      createDefaultAttribute () {
        const obj = IKUtils.deepCopy(defaultDate)
        obj.key = counter++
        return obj
      },
    },
  }
</script>

<style scoped>

</style>
