<template>
  <v-container class="px-6">
    <div class="d-flex">
      <div class="d-flex align-center py-4 pb-6">
        <span class="text-h2 font-weight-bold">{{ $t('reservationManage') }}</span>
      </div>
    </div>
    <v-container>
      <v-row>
        <v-col cols="5">
          <div
            class="pa-4"
          >
            <template v-if="isLoading">
              <div class="text-h4 font-weight-bold">
                {{ $t('营业时间') }}
              </div>
              <v-card
                v-for="i in openDateTime"
                :key="i.id"
                class="mt-2 pa-2"
                @click="editOpenTime(i)"
              >
                <div class="d-flex">
                  <div>
                    <div>
                      {{ i.weekDay.name }}
                    </div>
                    <div>
                      {{ $t('defaultEatTime', {defaultTime: i.useTime}) }}
                    </div>
                  </div>
                  <v-spacer/>
                  <div>
                    <div
                      v-for="x in i.openingTimespan"
                      :key="x.id"
                    >
                      <div>
                        {{ x.from }} - {{ x.to }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </template>
            <template v-else>
              <div
                class="d-flex align-center justify-center"
                style="height: 400px"
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                />
              </div>
            </template>
          </div>
        </v-col>
        <v-col cols="7">
          <v-card>
            <template v-if="searchOpenTime">
              <new-add-form
                v-if="searchOpenTime"
                :day-of-week="dayOfWeek"
                :default-reserve-time="openDateTime"
                :show-list="openTimeList"
                @reload="reload"
              />
            </template>
            <template v-else>
              <div
                class="d-flex align-center justify-center"
                style="height: 400px"
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                />
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
  import { getReserveSettings } from '@/common/Utlis/api'
  import OpeningTime from '@/model/takeaway/OpeningTime'
  import NewAddForm from '@/views/AadenPages/settings/reserveSetting/fragment/NewAddForm'
  import WeekDays from '@/model/common/WeekDays'
  import dayjs from 'dayjs'

  export default {
    name: 'OpenTime',
    components: { NewAddForm },
    data: function () {
      return {
        searchOpenTime: false,
        dayOfWeek: 1,
        openTimeList: [],
        isLoading: false,
        tab: null,
        MondayList: [],
        TuesdayList: [],
        WednesdayList: [],
        ThursdayList: [],
        FridayList: [],
        SaturdayList: [],
        SundayList: [],
        time: null,
        datePick: false,
        openDateTime: null,
      }
    },
    async mounted () {
      await this.reload()
    },
    methods: {
      editOpenTime (item) {
        this.searchOpenTime = false
        this.dayOfWeek = item.dayOfWeek
        this.searchOpenTime = true
      },
      async reload () {
        const result = WeekDays.getList()
        this.isLoading = false
        this.searchOpenTime = false
        const res = (await getReserveSettings()).weeklySettings.sort(function (a, b) {
          return a.dayOfWeek - b.dayOfWeek
        })
        this.openDateTime = res.map(it => {
          it.dayOfWeek = parseInt(it.dayOfWeek)
          it.weekDay = result.find(item => item.id === it.dayOfWeek)
          it.useTime = dayjs.duration(it.defaultEstimateDiningTime).as('hours')
          return it
        })
        this.openTimeList = (await OpeningTime.getList(true))
        this.isLoading = true
        this.searchOpenTime = true
      },
    },
  }
</script>

<style scoped>

</style>
